import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { DATATABLE_TYPE } from 'state-domains/constants';

import {
    buildConfigurationHeaderAddFieldUrl,
    buildConfigurationHeaderAddSampleFieldUrl,
} from '../../../network/urls';
import { sendRequestWithXSRFToken, convertToCamel } from '../../../utils';

export const addFieldToHeaderDefaultSection = (payload: {
    headerId: string;
    sectionId: string;
    field: string;
    index: number;
    configType?: DATATABLE_TYPE;
}): Observable<any> => {
    const { configType = DATATABLE_TYPE.HEADERS } = payload;
    const url =
        configType === DATATABLE_TYPE.HEADERS
            ? buildConfigurationHeaderAddFieldUrl(payload.headerId, payload.sectionId)
            : buildConfigurationHeaderAddSampleFieldUrl(payload.headerId, payload.sectionId);
    return sendRequestWithXSRFToken(
        url,
        { field: payload.field, index: payload.index },
        'POST',
    ).pipe(
        switchMap(({ response }: AjaxResponse<any>) => {
            const convertedField = convertToCamel(response);
            return observableOf(convertedField);
        }),
    );
};
